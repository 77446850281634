/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react'
import { downloadCSV } from '../../../_metronic/helpers/csv'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

interface DownloadCSVProps {
    fileName: string
    columns: any
    filters: any
}

const DownloadCSV: FC<DownloadCSVProps> = ({ fileName = 'export', columns, filters }) => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')
    const [loading, setLoading] = useState(false)

    const handleExport = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/leads/export?from_date=${filters.fromDate}&to_date=${filters.toDate}&sort=${filters.sortBy}&keyword=${filters.searchQuery}&status=${filters.leadTypeFilter}&viewed=${filters.viewedFilter}`,
                { headers: { Authorization: ` ${token}` } },
            )
            const allLeads = data.leads
            if (data.total === 0) {
                toast.error('No data available for export.')
            } else {
                downloadCSV({ data: allLeads, fileName, columns })
                toast.success(`${data.total} leads exported successfully!`)
            }
        } catch (error) {
            console.error('Error exporting CSV:', error)
            toast.error('Failed to export CSV.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <button className="btn btn-sm btn-dark flex-row mx-1" disabled={loading} onClick={handleExport}>
                {loading && <Spinner className="mx-1" size="sm" animation="border" />}
                Export CSV
            </button>
        </div>
    )
}

export default DownloadCSV
