import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const units = [
    {
        value: '',
        label: 'Select unit',
    },
    {
        value: 'K',
        label: 'K',
    },
    {
        value: 'M',
        label: 'M',
    },
    {
        value: 'B',
        label: 'B',
    },
    {
        value: 'T',
        label: 'T',
    },
]

const RealStateTransaction = () => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')

    const [transactionData, setTransactionData] = useState({
        transaction_month: '',
        transaction: '',
        transaction_unit: '',
        property_sale: '',
        property_sale_unit: '',
        unit_sold: '',
        unit_sold_unit: '',
    })

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getTransactions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTransactions = async () => {
        setIsLoading(true)
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/transaction`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            setTransactionData(data.result)
        } catch (error) {
            console.error(error)
            toast.error('Could not fetch transactions.')
        } finally {
            setIsLoading(false)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setTransactionData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/transaction`, transactionData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            setTransactionData(data.result)
            toast.success('Transaction data updated!')
        } catch (error) {
            console.error(error)
            toast.error('Could not update transaction data.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="container mt-4">
            <div className="card shadow-lg p-4">
                <div className="card-header border-0">
                    <h3 className="card-title align-items-center flex-column w-100">
                        <span className="card-label fw-bold fs-3 mb-1">Monthly Transaction Summary</span>
                    </h3>
                </div>

                {/* Month Dropdown */}
                <div className="mb-3">
                    <label className="form-label">Month:</label>
                    <select
                        className="form-select"
                        name="transaction_month"
                        value={transactionData.transaction_month}
                        onChange={handleChange}
                    >
                        {months.map((month) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Transaction Input with Unit */}
                <div className="mb-3">
                    <label className="form-label">Total Transactions:</label>
                    <div className="d-flex">
                        <input
                            type="number"
                            className="form-control me-2"
                            name="transaction"
                            value={transactionData.transaction}
                            onChange={handleChange}
                        />
                        <select
                            className="form-select w-100"
                            name="transaction_unit"
                            value={transactionData.transaction_unit}
                            onChange={handleChange}
                        >
                            {units.map((unit) => (
                                <option key={unit.label} value={unit.value}>
                                    {unit.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Property Sale Input with Unit */}
                <div className="mb-3">
                    <label className="form-label">Property Sale:</label>
                    <div className="d-flex">
                        <input
                            type="number"
                            className="form-control me-2"
                            name="property_sale"
                            value={transactionData.property_sale}
                            onChange={handleChange}
                        />
                        <select
                            className="form-select w-100"
                            name="property_sale_unit"
                            value={transactionData.property_sale_unit}
                            onChange={handleChange}
                        >
                            {units.map((unit) => (
                                <option key={unit.label} value={unit.value}>
                                    {unit.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Units Sold Input with Unit */}
                <div className="mb-3">
                    <label className="form-label">Units Sold:</label>
                    <div className="d-flex">
                        <input
                            type="number"
                            className="form-control me-2"
                            name="unit_sold"
                            value={transactionData.unit_sold}
                            onChange={handleChange}
                        />
                        <select
                            className="form-select w-100"
                            name="unit_sold_unit"
                            value={transactionData.unit_sold_unit}
                            onChange={handleChange}
                        >
                            {units.map((unit) => (
                                <option key={unit.label} value={unit.value}>
                                    {unit.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <button className="btn btn-dark w-100" onClick={handleSave} disabled={isLoading}>
                    {isLoading && <Spinner className="spinner-border-sm me-2" animation="border" variant="light" />}
                    {isLoading ? 'Updating...' : 'Update'}
                </button>
            </div>
        </div>
    )
}

export default RealStateTransaction
