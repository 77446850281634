import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { PageTitle } from '../../_metronic/layout/core'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'

import 'rsuite/dist/rsuite.min.css'
import { Pencil } from 'react-bootstrap-icons'
import AddEditUserModal from '../modules/users/AddEditUserModal'

const Users = () => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')
    const [offset, setOffset] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')
    const [allData, setAllData] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [dataPerPage, setDataPerPage] = useState(10)
    const [roleTypeFilter, setRoleTypeFilter] = useState('')
    const [contentLoading, setContentLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [itemData, setItemData] = useState()

    const handleClose = () => {
        setItemData()
        setShow(false)
    }

    const handlePageClick = (event) => {
        const newOffset = event.selected * dataPerPage
        setOffset(newOffset)
        setDataPerPage(dataPerPage)
    }

    useEffect(() => {
        setOffset(0)
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, searchQuery, dataPerPage, roleTypeFilter])

    const getAllData = (data, offset) =>
        data.map((item) => (
            <tr key={item._id} className={`widget-9-item`}>
                <td>{item?.full_name}</td>
                <td>{item?.email}</td>
                <td>{item?.role}</td>
                <td>
                    <span className={`badge badge-light-${item?.is_deleted ? 'danger' : 'success'}`}>
                        {item?.is_deleted ? 'Disabled' : 'Active'}
                    </span>
                </td>
                <td className="">
                    <div className="d-flex flex-shrink-0">
                        <button
                            onClick={() => editDetails(item?._id, item)}
                            className="btn btn-icon btn-bg-light btn-icon-theme btn-sm me-1"
                            title="View Details"
                        >
                            <Pencil className="text-theme" />
                        </button>

                        {item?.role === 'Admin' ? (
                            ''
                        ) : (
                            <>
                                <button
                                    onClick={() => resetPassword(item._id)}
                                    className="btn btn-icon btn-bg-light btn-icon-theme btn-sm  me-1"
                                    title="Reset Password"
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr029.svg"
                                        className="svg-icon-3 text-theme text-white"
                                    />
                                </button>
                                {item.is_deleted ? (
                                    <button
                                        onClick={() => enableItem(item._id)}
                                        className="btn btn-icon btn-bg-light btn-icon-theme btn-sm me-1 "
                                        title="Enable User"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr085.svg"
                                            className="svg-icon-3 text-theme text-white"
                                        />
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => disableItem(item._id)}
                                        className="btn btn-icon btn-bg-light btn-icon-theme btn-sm  me-1"
                                        title="Disable User"
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr061.svg"
                                            className="svg-icon-3 text-theme text-white"
                                        />
                                    </button>
                                )}
                                <button
                                    onClick={() => deleteItem(item._id)}
                                    className="btn btn-icon btn-bg-light btn-icon-theme btn-sm me-1 "
                                    title="Delete User"
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/general/gen027.svg"
                                        className="svg-icon-3 text-theme text-white"
                                    />
                                </button>
                            </>
                        )}
                    </div>
                </td>
            </tr>
        ))

    const getData = async () => {
        setContentLoading(true)
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/adminuser/user?keyword=${searchQuery}&offset=${offset}&limit=${dataPerPage}&role=${roleTypeFilter}`,
                { headers: { Authorization: ` ${token}` } },
            )

            const data = res.data
            const page_data = data.result
            const pageData = getAllData(page_data)

            setAllData(pageData)
            setPageCount(Math.ceil(data.total / dataPerPage))
            setTotalItems(data.total)
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        } finally {
            setContentLoading(false)
        }
    }

    const editDetails = (id, item) => {
        setItemData(item)
        setShow(true)
    }

    const newUser = () => {
        setShow(true)
    }

    const deleteItem = (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            axios
                .delete(`${process.env.REACT_APP_API_URL}/adminuser/user/${id}`, {
                    headers: { Authorization: ` ${token}` },
                })
                .then((res) => {
                    toast.success(res.data.message)
                    getData()
                })
                .catch((error) => {
                    console.log(error)
                    toast.error('Something went wrong')
                })
        }
    }
    const resetPassword = (id) => {
        if (window.confirm("Are you sure you want to reset this user's password?")) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/adminuser/user/${id}/reset-password`, {
                    headers: { Authorization: ` ${token}` },
                })
                .then((res) => {
                    toast.success(res.data.message)
                })
                .catch((error) => {
                    console.log(error)
                    toast.error('Something went wrong')
                })
        }
    }

    const disableItem = (id) => {
        if (window.confirm('Are you sure you want to disable this account?')) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}/adminuser/user/${id}`,
                    { is_deleted: true },
                    {
                        headers: { Authorization: ` ${token}` },
                    },
                )
                .then((res) => {
                    toast.success(res.data.message)
                    getData()
                })
                .catch((error) => {
                    console.log(error)
                    toast.error('Something went wrong')
                })
        }
    }

    const enableItem = (id) => {
        if (window.confirm('Are you sure you want to enable this account?')) {
            axios
                .patch(
                    `${process.env.REACT_APP_API_URL}/adminuser/user/${id}`,
                    { is_deleted: false },
                    {
                        headers: { Authorization: ` ${token}` },
                    },
                )
                .then((res) => {
                    toast.success(res.data.message)
                    getData()
                })
                .catch((error) => {
                    console.log(error)
                    toast.error('Something went wrong')
                })
        }
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Leads</PageTitle>
            <div className={`card card-light`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            Total Users&nbsp;
                            <span className="p-1 mt-1 fw-semibold fs-7 btn-theme rounded">
                                {totalItems < 10 ? '0' + totalItems : totalItems}
                            </span>
                        </span>
                    </h3>
                    <div
                        className="card-toolbar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-trigger="hover"
                        title="Click to add a user"
                    >
                        <button onClick={newUser} className="btn btn-sm btn-dark">
                            <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3 " />
                            New User
                        </button>
                    </div>
                </div>
                <div className="d-flex flex-wrap px-5 bg-light p-3 shadow shadow-sm border">
                    <div className="row w-100">
                        {/* First row */}
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Search</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                value={searchQuery}
                            />
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Role</label>
                            <select
                                aria-label="Default select example"
                                className="form-select form-select-sm"
                                onChange={(e) => setRoleTypeFilter(e.target.value)}
                                value={roleTypeFilter}
                            >
                                <option value="">Select Role</option>
                                {/* <option value='Admin'>Admin</option> */}
                                <option value="Marketer">Marketer</option>
                                <option value="Manager">Manager</option>
                                <option value="Agent">Agent</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Items per Page</label>
                            <select
                                className="form-select form-select-sm"
                                value={dataPerPage}
                                onChange={(e) => setDataPerPage(e.target.value)}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label d-block">&nbsp;</label>
                            <button
                                className="btn btn-sm btn-dark"
                                onClick={() => {
                                    setSearchQuery('')
                                    setRoleTypeFilter('')
                                    setDataPerPage(10)
                                }}
                            >
                                Clear Filters
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-body py-3">
                    <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                                <tr className="fw-bold">
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contentLoading ? (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <div className="spinner-border" size="sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                ) : allData.length === 0 ? (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            No data found
                                        </td>
                                    </tr>
                                ) : (
                                    allData
                                )}
                            </tbody>
                        </table>
                        <div className="text-center">
                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination mb-5'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* ====================================== */}
            {/* Modal */}
            {/* ====================================== */}

            <AddEditUserModal data={itemData} showModal={show} toggleModal={handleClose} onSuccess={getData} />
        </>
    )
}

export default Users
