import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Blogs from '../pages/Blogs'
import Leads from '../pages/Leads'
import FBLeads from '../pages/FBLeads'
import { useAuth } from '../modules/auth'
import Users from '../pages/Users'
import RealStateTransaction from '../pages/RealStateTransactions'

const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const PrivateRoutes = () => {
  const { isAdmin } = useAuth()

  // Define routes dynamically
  const routes = [
    { path: 'dashboard', element: <DashboardWrapper />, isPrivate: true },
    { path: 'leads', element: <Leads />, isPrivate: true },
    { path: 'fb-leads', element: <FBLeads />, isPrivate: true },
    { path: 'blogs', element: <Blogs />, isPrivate: true },
    {
      path: 'crafted/account/*',
      element: (
        <SuspensedView>
          <AccountPage />
        </SuspensedView>
      ),
      isPrivate: true,
    },
    { path: 'users', element: <Users />, isPrivate: isAdmin },
    {
      path: 'transactions',
      element: <RealStateTransaction />,
      isPrivate: isAdmin,
    },
  ]

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Dynamically map routes */}
        {routes
          .filter((route) => route.isPrivate)
          .map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
