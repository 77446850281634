import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/adminuser/verify_token`
export const LOGIN_URL = `${API_URL}/adminuser/signin`
export const REGISTER_URL = `${API_URL}/adminuser/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/adminuser/reset-password`

// Server should return AuthModel
export function login(email: string, password: string) {
    return axios.post<AuthModel>(LOGIN_URL, {
        email,
        password,
    })
}

// Server should return AuthModel
export function register(email: string, fullname: string, password: string, cpassword: string, role: Number) {
    return axios.post(REGISTER_URL, {
        email,
        full_name: fullname,
        password,
        cpassword,
        role: 1,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    })
}

export function getUserByToken(token: string, user: string) {
    return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
        api_token: token,
        user,
    })
}
