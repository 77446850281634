import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

const AssignAgentModal = ({ show, onHide, selectedRows, onAssignAgent, onSuccess }) => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')
    const [selectedAgent, setSelectedAgent] = useState('')
    const [agentList, setAgentList] = useState('')

    const getAgents = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/leads/agents`, {
                headers: { Authorization: ` ${token}` },
            })

            setAgentList(data?.result || [])
        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const handleBulkAssignAgent = async () => {
        if (!selectedAgent) return

        try {
            const res = await axios.patch(
                `${process.env.REACT_APP_API_URL}/leads/bulk-assign`,
                {
                    ids: selectedRows,
                    userId: selectedAgent,
                },
                {
                    headers: { Authorization: ` ${token}` },
                },
            )

            toast.success(res.data.message)
            setSelectedAgent('')
            onSuccess()
        } catch (error) {
            console.log('bulk assign error', error)
            toast.error('Something went wrong')
        }
    }

    useEffect(() => {
        getAgents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Agent to Selected Leads</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <label className="form-label">Select Agent</label>
                    <select
                        className="form-select"
                        value={selectedAgent}
                        onChange={(e) => setSelectedAgent(e.target.value)}
                    >
                        <option value="">Select an agent</option>
                        {!!agentList.length &&
                            agentList?.map((agent) => (
                                <option key={agent._id} value={agent._id}>
                                    {agent.full_name}
                                </option>
                            ))}
                    </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-secondary" onClick={onHide}>
                    Cancel
                </button>
                <button className="btn btn-sm btn-dark" onClick={handleBulkAssignAgent} disabled={!selectedAgent}>
                    Assign Agent
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssignAgentModal
