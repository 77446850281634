/* eslint-disable @typescript-eslint/no-explicit-any */
export function dataToCsv(data: any, columns: any) {
  const headers = columns.map((el: { title: string; key: string }) => el.title)
  const keys = columns.map((el: { title: string; key: string }) => el.key)

  const extractData = (obj: any): any => {
    const rows: any = []

    keys.forEach((key: string) => {
      let value = obj
      key.split('.').forEach((part) => {
        value = value ? value[part] : undefined
      })

      const prefix = columns.find((el: any) => el.key === key)?.prefix

      const stringValue = value !== undefined ? String(value) : ''
      const finalValue = prefix ? prefix(stringValue) : stringValue
      const escapedValue = finalValue.replace(/"/g, '""')
      rows.push(escapedValue)
    })

    return rows
  }

  // Create CSV header row
  const csv = [headers.join(',')]

  // Extract and convert object values to CSV format
  data.forEach((row: any) => {
    const values = extractData(row)
    csv.push(values.join(','))
  })

  // Joining CSV rows with newlines
  return csv.join('\r\n')
}

export function downloadCSV({ data, fileName, columns }: any) {
  const csvArray = dataToCsv(data, columns)
  const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  const pom = document.createElement('a')
  pom.href = url
  pom.setAttribute('download', `${fileName}.csv`)
  pom.click()
}
