import React, { useState } from 'react'
import Papa from 'papaparse'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'

function convertExponentialToDecimal(expStr) {
    // Check if the value is a string and contains "e" (exponential notation)
    if (typeof expStr === 'string' && /e/i.test(expStr)) {
        // Convert to a number first then to a string in full notation without grouping.
        // Using toLocaleString with 'fullwide' ensures we don't lose precision.
        return Number(expStr).toLocaleString('fullwide', { useGrouping: false })
    }
    return expStr
}

const ImportCSV = ({ onSuccess }) => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')

    const [isLoading, setIsLoading] = useState(false)
    const [mappedData, setMappedData] = useState([])
    const [mapping, setMapping] = useState({})

    const expectedColumns = ['Name', 'Email', 'Phone', 'Property URL', 'Lead Type', 'Comment', 'Status']
    const [showModal, setShowModal] = useState(false)

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        if (!file) {
            toast.error('Please select a file!')
            return
        }

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: false,
            transform: (value, field) => {
                // For the Phone field, check if it is in exponential notation and convert it
                if (field.toLowerCase() === 'phone') {
                    return convertExponentialToDecimal(value)
                }
                return value
            },
            complete: (results) => {
                const csvColumns = Object.keys(results.data[0])
                const autoMapping = {}

                expectedColumns.forEach((expected) => {
                    const match = csvColumns.find((col) => col.toLowerCase() === expected.toLowerCase())
                    if (match) autoMapping[expected] = match
                })

                // Ensure "Phone" is treated as a string
                const formattedData = results.data.map((row) => ({
                    ...row,
                    Phone: row.Phone ? String(row.Phone) : '', // Convert phone number to string
                }))

                setMapping(autoMapping)
                setMappedData(formattedData)
            },
        })
    }

    const handleUpload = async () => {
        if (!mappedData.length) return toast.error('Please select a CSV file!')

        setIsLoading(true)

        const transformedData = mappedData.map((row) => {
            const newRow = {}
            Object.keys(mapping).forEach((expected) => {
                newRow[expected] = row[mapping[expected]] || null
            })
            return newRow
        })

        // Validation: Check required fields and allowed values for Lead Type.
        const requiredFields = ['Name', 'Phone', 'Email']
        const allowedLeadTypes = ['new', 'contacted', 'qualified', 'lost']

        for (let i = 0; i < transformedData.length; i++) {
            const row = transformedData[i]
            // Validate required fields
            for (let field of requiredFields) {
                if (!row[field] || row[field].toString().trim() === '') {
                    toast.error(`Row ${i + 1}: ${field} is required and should not be empty.`)
                    setIsLoading(false)
                    return
                }
            }

            // Validate Lead Type (if present)
            if (!!row['Lead Type']) {
                const leadTypeValue = row['Lead Type'].toString().trim().toLowerCase()
                if (!allowedLeadTypes.includes(leadTypeValue)) {
                    toast.error(`Row ${i + 1}: Lead Type must be one of ${allowedLeadTypes.join(', ')}.`)
                    setIsLoading(false)
                    return
                }
            }
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/leads/import`,
                { leads: transformedData },
                { headers: { Authorization: ` ${token}` } },
            )
            toast.success('Leads uploaded!')
            onSuccess()
            closeModal()
        } catch (error) {
            toast.error('Some error occurred. Try Again!')
        } finally {
            setIsLoading(false)
        }
    }

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
        setMappedData([])
        setMapping({})
    }

    return (
        <div>
            <button className="btn btn-sm btn-dark" onClick={openModal}>
                Import CSV
            </button>

            <Modal className="modal fade" size="lg" show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="h5">Import Leads</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body scroll-y mx-5 mx-xl-18 pt-2 pb-2">
                    {/* Disclaimer / Note */}
                    <div className="mb-3">
                        <small className="text-muted">
                            <span className="text-danger">Note:</span> The first row of the CSV file should be headers
                            with the following column names as expected: "Name", "Phone", "Email". These fields are
                            required and should not be empty in any row.
                        </small>
                    </div>

                    <input
                        className="form-control form-control-sm mb-3"
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                    />

                    {Object.keys(mapping).length > 0 && (
                        <div>
                            <h5 className="mb-3">Columns</h5>
                            {expectedColumns.map((expected) => (
                                <div className="mb-3" key={expected}>
                                    <label className="form-label">{expected}</label>
                                    <select
                                        className="form-select form-select-sm"
                                        value={mapping[expected] || ''}
                                        onChange={(e) => setMapping({ ...mapping, [expected]: e.target.value })}
                                    >
                                        <option value="">Select Column</option>
                                        {Object.keys(mappedData[0]).map((col) => (
                                            <option key={col} value={col}>
                                                {col}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="p-0 pb-2">
                    <button
                        type="button"
                        className="btn btn-sm w-25 btn-dark mt-8"
                        onClick={handleUpload}
                        disabled={isLoading}
                    >
                        {isLoading && <Spinner className="spinner-border-sm me-2" animation="border" variant="light" />}
                        Upload
                    </button>
                    <button type="button" className="btn btn-sm w-25 btn-secondary mt-8" onClick={closeModal}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ImportCSV
