import { Formik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { Form, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useAuth } from '../auth'

const AddEditUserModal = ({ showModal, toggleModal, data, onSuccess }) => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')
    const { auth } = useAuth()
    const isRoleDisabled = auth.user === data?._id

    const isEdit = !!data
    const initialValues = {
        full_name: data?.full_name ?? '',
        email: data?.email ?? '',
        role: data?.role ?? '',
    }

    const handleFormSubmit = async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true)
        const payload = {
            full_name: values.full_name,
            email: values.email,
            role: values.role,
        }

        let toastSuccessMessage = 'User added successfully.'
        try {
            if (isEdit) {
                await axios.patch(`${process.env.REACT_APP_API_URL}/adminuser/user/${data._id}`, payload, {
                    headers: { Authorization: ` ${token}` },
                })
                toastSuccessMessage = 'Staff updated successfully.'
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/adminuser/user`, payload, {
                    headers: { Authorization: ` ${token}` },
                })
            }
            toast.success(toastSuccessMessage)
            onSuccess()
            toggleModal()
        } catch (error) {
            toast.error(error.response.data.message || 'Something went wrong')
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Modal show={showModal} onHide={toggleModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="font-18 font-weight-600">{isEdit ? 'Edit' : 'Add'} User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
                    {({ values, setFieldValue, handleSubmit, isSubmitting, status }) => (
                        <form onSubmit={handleSubmit}>
                            <Form.Group className="mt-3" controlId="StaffName">
                                <Form.Label>
                                    Full Name<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter name"
                                    className="text-dark"
                                    name="full_name"
                                    value={values.full_name}
                                    onChange={(e) => setFieldValue('full_name', e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mt-3" controlId="StaffEmail">
                                <Form.Label>
                                    Email
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Enter Email"
                                    name="Email"
                                    className="text-dark"
                                    disabled={isEdit}
                                    value={values.email}
                                    onChange={(e) => setFieldValue('email', e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label>Role</Form.Label>

                                <select
                                    aria-label="Select User Role"
                                    className="form-select form-select-sm text-dark"
                                    onChange={(e) => setFieldValue('role', e.target.value)}
                                    value={values.role}
                                    disabled={isRoleDisabled}
                                >
                                    <option value="" className="text-dark">
                                        Select Role
                                    </option>
                                    {/* <option value='Admin'>Admin</option> */}
                                    <option value="Marketer" className="text-dark">
                                        Marketer
                                    </option>
                                    <option value="Manager" className="text-dark">
                                        Manager
                                    </option>
                                    <option value="Agent" className="text-dark">
                                        Agent
                                    </option>
                                </select>
                            </Form.Group>

                            {status && <p className="px-5 alert alert-danger alert-text font-weight-bold">{status}</p>}
                            <div className="border-top mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-dark mt-3"
                                    id="btn-submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner className="spinner-border-sm me-2" animation="border" variant="dark" />
                                    )}
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default AddEditUserModal
