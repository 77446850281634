import { useEffect, useState } from 'react'
import { PageTitle } from '../../_metronic/layout/core'
import { KTSVG } from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import { Funnel, Calendar } from 'react-bootstrap-icons'

import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css fil

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Blogs = () => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')
    const [offset, setOffset] = useState(0)
    const [fromDate, setFromDate] = useState('') //new Date().toISOString().slice(0, 10) '2020-01-01'
    const [toDate, setToDate] = useState('')
    const [sortBy, setSortBy] = useState(-1)
    const [contentLoading, setContentLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [allData, setAllData] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [dataPerPage, setDataPerPage] = useState(10)
    const [lgshow, setShow] = useState(false)
    const [blogTitle, setBlogTitle] = useState('')
    const [blogDescription, setBlogDescription] = useState('')
    const [blogMetaTitle, setBlogMetaTitle] = useState('')
    const [blogMetaKeywords, setBlogMetaKeywords] = useState('')
    const [blogMetaDescription, setBlogMetaDescription] = useState('')
    // const [blogDescription, setBlogDescription] = useState('');
    const [blogStatus, setBlogStatus] = useState(1)
    const [quillValue1, setQuillValue1] = useState('')
    const [quillValue2, setQuillValue2] = useState('')
    const [fileFormatErrorThumbnail1, setFileFormatErrorThumbnail1] = useState(false)
    const [isEditThumbnail1, setIsEditThumbnail1] = useState(false)
    const [fileSizeErrorThumbnail1, setFileSizeErrorThumbnail1] = useState(false)
    const [fileFormatError, setFileFormatError] = useState(false)
    const [thumbnail_selected1, setThumbnailSelected1] = useState('')

    const [fileFormatErrorThumbnail2, setFileFormatErrorThumbnail2] = useState(false)
    const [isEditThumbnail2, setIsEditThumbnail2] = useState(false)
    const [fileSizeErrorThumbnail2, setFileSizeErrorThumbnail2] = useState(false)
    const [thumbnail_selected2, setThumbnailSelected2] = useState('')

    const [modalTitle, setModalTitle] = useState('View Order Details')
    const [viewMode, setViewMode] = useState(false)

    const [blogId, setBlogId] = useState('')
    const [calenderDates, setCalenderDates] = useState([
        {
            startDate: null,
            endDate: new Date(),
            key: 'selection',
        },
    ])
    // const theme = 'snow';

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            //   color
            [{ color: [] }, { background: [] }],
            // font
            [{ font: [] }],
            // align
            [{ align: [] }],
            ['link'],
            ['clean'],
        ],
    }

    // const  formats = [
    //     'header',
    //     'bold', 'italic', 'underline', 'strike', 'blockquote',
    //     'list', 'bullet', 'indent',
    //     'link', 'image'
    //   ],
    // const { quill, quillRef } = useQuill({ theme, modules });

    useEffect(() => {
        getData()
    }, [sortBy, offset, calenderDates, fromDate, toDate, searchQuery])

    const getAllData = (data, offset) => {
        return data.map((item) => {
            // const blog_data = order.order_details[0];
            return (
                <tr key={item._id}>
                    <td></td>
                    <td>{item?.title}</td>
                    <td>{item?.metaTitle}</td>
                    <td>{item?.meta_keywords}</td>
                    <td>
                        {/* status */}
                        <span className={`badge badge-light-${item?.status === 1 ? 'success' : 'danger'} badge-inline`}>
                            {item?.status === 1 ? 'Active' : 'Inactive'}
                        </span>
                    </td>

                    <td className="">
                        <div className="d-flex flex-shrink-0">
                            <button
                                onClick={() => viewDetails(item?._id)}
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                title="View Details"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/general/gen028.svg"
                                    className="svg-icon-3 text-theme"
                                />
                            </button>

                            <button
                                onClick={() => editBlog(item._id)}
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                            >
                                <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-3 text-theme" />
                            </button>
                            <button
                                onClick={() => deleteBlog(item._id)}
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
                            >
                                <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-3 text-theme"
                                />
                            </button>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const getData = async () => {
        setContentLoading(true)

        // if (searchQuery === '' && fromDate === undefined && toDate === undefined && sortBy === -1) {
        const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/blogs?from_date=${fromDate}&to_date=${toDate}&sort=${sortBy}&keyword=${searchQuery}&offset=${offset}&limit=${dataPerPage}`,
            { headers: { Authorization: ` ${token}` } },
        )

        const data = res.data
        // console.log(data);
        const blog_data = data.blogs
        const blogData = getAllData(blog_data)
        // console.log(blogData);
        setAllData(blogData)
        setPageCount(Math.ceil(data.total / dataPerPage))
        setTotalItems(data.total)
        // }else{
        //     getDataBySearch();

        // }
        setContentLoading(false)
    }

    const handlePageClick = (event) => {
        const newOffset = event.selected * dataPerPage
        // console.log(newOffset);
        setOffset(newOffset)
        setDataPerPage(dataPerPage)
    }
    const viewDetails = (blog_id) => {
        try {
            axios
                .get(`${process.env.REACT_APP_API_URL}/blogs/${blog_id}`, { headers: { Authorization: ` ${token}` } })
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        const data = res.data.blog
                        setBlogId(data._id)
                        setBlogTitle(data.title)
                        setBlogMetaTitle(data.metaTitle)
                        setBlogMetaKeywords(data.meta_keywords)

                        setBlogMetaDescription(data.meta_description)
                        setBlogStatus(data.status)
                        setBlogDescription(data.description)
                        setQuillValue1(data.content1)
                        setThumbnailSelected1(data.featured_image1)
                        setIsEditThumbnail1(true)
                        setQuillValue2(data.content2)
                        setThumbnailSelected2(data.featured_image2)
                        setIsEditThumbnail2(true)
                        // if(quill){
                        //     quill.clipboard.dangerouslyPasteHTML(data.content);
                        // }
                        // console.log(data.content);
                        // setBlogDescription(data.description);
                        setViewMode(true)
                        handleShow('', 'View Blog', 'View Blog', true)
                    } else {
                        toast.error('Something went wrong')
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error('Something went wrong')
                })
        } catch (err) {
            console.log(err)
        }
    }
    const handleThumbnail1 = (e) => {
        setFileSizeErrorThumbnail1(false)
        setFileFormatErrorThumbnail1(false)

        if (e.target.files[0].size > 300000) {
            setFileSizeErrorThumbnail1(true)
            e.target.value = null
            return
        } else {
            if (
                e.target.files[0].type !== 'image/jpeg' &&
                e.target.files[0].type !== 'image/png' &&
                e.target.files[0].type !== 'image/jpg' &&
                e.target.files[0].type !== 'image/svg' &&
                e.target.files[0].type !== 'image/svg+xml' &&
                e.target.files[0].type !== 'image/webp'
            ) {
                // toast.error('File format not supported');
                setFileFormatErrorThumbnail1(true)
                e.target.value = null
                return
            } else {
                setThumbnailSelected1(e.target.files[0])
                setIsEditThumbnail1(false)
            }
        }
    }

    const handleThumbnail2 = (e) => {
        setFileSizeErrorThumbnail2(false)
        setFileFormatErrorThumbnail2(false)

        if (e.target.files[0].size > 300000) {
            setFileSizeErrorThumbnail2(true)
            e.target.value = null
            return
        } else {
            if (
                e.target.files[0].type !== 'image/jpeg' &&
                e.target.files[0].type !== 'image/png' &&
                e.target.files[0].type !== 'image/jpg' &&
                e.target.files[0].type !== 'image/svg' &&
                e.target.files[0].type !== 'image/svg+xml' &&
                e.target.files[0].type !== 'image/webp'
            ) {
                // toast.error('File format not supported');
                setFileFormatErrorThumbnail2(true)
                e.target.value = null
                return
            } else {
                setThumbnailSelected2(e.target.files[0])
                setIsEditThumbnail2(false)
            }
        }
    }

    const handleShow = (e, title = 'View Blog', button = 'View Blog', viewMode = false) => {
        if (viewMode) {
            setViewMode(true)
        } else {
            setViewMode(false)
        }

        // console.log(title, button);
        setModalTitle(title)
        setModalTitle(button)
        setShow(true)
    }
    const handleClose = () => {
        setBlogId('')
        setBlogTitle('')
        setBlogMetaTitle('')
        setBlogMetaKeywords('')
        setBlogMetaDescription('')
        setBlogStatus(1)
        setQuillValue1('')
        setQuillValue2('')
        setBlogDescription('')
        setThumbnailSelected1('')
        setFileSizeErrorThumbnail1(false)
        setFileFormatErrorThumbnail1(false)
        setIsEditThumbnail1(false)
        setThumbnailSelected2('')
        setFileSizeErrorThumbnail2(false)
        setFileFormatErrorThumbnail2(false)
        setIsEditThumbnail2(false)

        // quillRef.current = null;

        // if(quill){
        //     // destroy quill
        //     quill.clipboard.dangerouslyPasteHTML('');
        //     // remove quill

        // }
        // quill to empty
        // quill.root.innerHTML = '';
        setViewMode(false)

        setShow(false)
    }

    const handleBlogForm = (e) => {
        e.preventDefault()
        if (blogId !== '') {
            const formData = new FormData()
            formData.append('title', blogTitle)
            formData.append('metaTitle', blogMetaTitle)
            formData.append('meta_keywords', blogMetaKeywords)
            formData.append('meta_description', blogMetaDescription)
            formData.append('status', blogStatus)
            formData.append('content1', quillValue1)
            formData.append('content2', quillValue2)
            formData.append('description', blogDescription)
            formData.append('photo1', thumbnail_selected1)
            formData.append('photo2', thumbnail_selected2)

            const btn = document.getElementById('btn-submit')
            btn.disabled = true
            // spinner
            btn.innerHTML = `
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
            `

            try {
                axios
                    .patch(`${process.env.REACT_APP_API_URL}/blogs/${blogId}`, formData, {
                        headers: { Authorization: ` ${token}` },
                    })
                    .then((res) => {
                        if (res.data.statusCode === 200) {
                            handleClose()
                            getData()
                            toast.success(res.data.message)
                        } else {
                            toast.error(res.data.message)
                        }
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message)
                    })
            } catch (err) {
                console.log(err)
                toast.error('Something went wrong!')
            } finally {
                btn.disabled = false
                btn.innerHTML = 'Upload New Blog'
            }
        } else {
            const formData = new FormData()
            formData.append('title', blogTitle)
            formData.append('metaTitle', blogMetaTitle)
            formData.append('meta_keywords', blogMetaKeywords)
            formData.append('meta_description', blogMetaDescription)
            formData.append('status', blogStatus)
            formData.append('content1', quillValue1)
            formData.append('content2', quillValue2)
            formData.append('description', blogDescription)
            formData.append('photo1', thumbnail_selected1)
            formData.append('photo2', thumbnail_selected2)

            const btn = document.getElementById('btn-submit')
            btn.disabled = true
            // spinner
            btn.innerHTML = `
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
            `

            try {
                axios
                    .post(`${process.env.REACT_APP_API_URL}/blogs`, formData, {
                        headers: { Authorization: ` ${token}` },
                    })
                    .then((res) => {
                        if (res.data.statusCode === 201) {
                            handleClose()
                            getData()
                            toast.success(res.data.message)
                        } else {
                            toast.error(res.data.message)
                        }
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message)
                    })
            } catch (err) {
                console.log(err)
                toast.error('Something went wrong!')
            } finally {
                btn.disabled = false
                btn.innerHTML = 'Upload New Blog'
            }
        }
    }

    const editBlog = (blog_id) => {
        try {
            axios
                .get(`${process.env.REACT_APP_API_URL}/blogs/${blog_id}`, { headers: { Authorization: ` ${token}` } })
                .then((res) => {
                    // console.log(res);
                    if (res.data.statusCode === 200) {
                        // console.log(res.data.blog);
                        const data = res.data.blog
                        // console.log(data);
                        setBlogId(data._id)
                        setBlogTitle(data.title)
                        setBlogMetaTitle(data.metaTitle)
                        setBlogMetaKeywords(data.meta_keywords)
                        setBlogMetaDescription(data.meta_description)
                        setQuillValue1(data.content1)
                        setQuillValue2(data.content2)
                        setBlogDescription(data.description)
                        setThumbnailSelected1(data.featured_image1)
                        setThumbnailSelected2(data.featured_image2)
                        // setThumbnail(res.data.thumbnail);
                        setIsEditThumbnail1(true)
                        setIsEditThumbnail2(true)
                        // quill to set data
                        // quill.root.innerHTML = data.content;
                        // if(quill){
                        //     quill.clipboard.dangerouslyPasteHTML(data.content);
                        // }
                        // quill.clipboard.dangerouslyPasteHTML(data.content);
                        setBlogStatus(data.status)
                        handleShow('', 'Edit Blog', 'Edit Blog', false)
                    } else {
                        toast.error(res.data.message)
                    }
                })
                .catch((err) => {
                    toast.error('Something went wrong!')
                })
        } catch (err) {
            console.log(err)
            toast.error('Something went wrong!')
        }
    }

    const deleteBlog = (blog_id) => {
        try {
            // confirm
            if (window.confirm('Are you sure you want to delete this blog?')) {
                axios
                    .delete(`${process.env.REACT_APP_API_URL}/blogs/${blog_id}`, {
                        headers: { Authorization: ` ${token}` },
                    })
                    .then((res) => {
                        if (res.data.statusCode === 200) {
                            getData()
                            toast.success(res.data.message)
                        } else {
                            toast.error(res.data.message)
                        }
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message)
                    })
            }
        } catch (err) {
            console.log(err)
            toast.error('Something went wrong!')
        }
    }

    const formatDate = (date) => {
        // console.log(date);
        // Oct 19, 2022 9:00 AM
        const d = new Date(date)
        const year = d.getFullYear()
        const month = d.getMonth() + 1
        // month name
        const monthName = d.toLocaleString('default', { month: 'short' })

        const day = d.getDate()
        const hours = d.getHours()
        const minutes = d.getMinutes()
        const seconds = d.getSeconds()
        // hourse to 12 and add am pm
        const hours12 = hours > 12 ? hours - 12 : hours
        const ampm = hours >= 12 ? 'PM' : 'AM'

        const minutes0 = minutes < 10 ? `0${minutes}` : minutes
        const hours0 = hours12 < 10 ? `0${hours12}` : hours12
        const seconds0 = seconds < 10 ? `0${seconds}` : seconds

        const formattedDate = `${monthName} ${day}, ${year} ${hours0}:${minutes0} ${ampm}`
        return formattedDate
    }

    const changeDataRange = (e) => {
        setCalenderDates([e.selection])
        // convert "Tue Mar 14 2023 00:00:00 GMT+0500 (Pakistan Standard Time)" to "2023-03-14"
        const startdate = new Date(e.selection.startDate)
        const enddate = new Date(e.selection.endDate)
        const startYear = startdate.getFullYear()
        const startMonth = startdate.getMonth() + 1
        const startDay = startdate.getDate()
        const endYear = enddate.getFullYear()
        const endMonth = enddate.getMonth() + 1
        const endDay = enddate.getDate()
        const startDate = `${startYear}-${startMonth}-${startDay}`
        const endDate = `${endYear}-${endMonth}-${endDay}`

        setFromDate(startDate)
        setToDate(endDate)
    }

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Blogs</PageTitle>
            <div className={`card card-light`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                            Total Blogs{' '}
                            <span className="p-1 mt-1 fw-semibold fs-7 btn-theme rounded">
                                {totalItems < 10 ? '0' + totalItems : totalItems}
                            </span>
                        </span>
                    </h3>
                    <div
                        className="card-toolbar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-trigger="hover"
                        title="Click new blog"
                    >
                        <button
                            onClick={(e) => handleShow(e, 'Upload New Blog', 'Upload New Blog')}
                            className="btn btn-sm btn-dark"
                            data-bs-toggle="modal"
                            data-bs-target="#category_modal"
                        >
                            <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3 " />
                            New Blog
                        </button>
                    </div>
                </div>
                <div className="d-flex px-5">
                    {/* <div className='card-toolbar'> */}
                    <div className="min-w-200px pe-2">
                        {/* <Select options={patientSelect} onChange={e=>setPatientSearch(e.value)} placeholder="Select Patient" /> */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                        />
                    </div>
                    {/* from date - to date calender */}
                    <div className="dropdown dropdown-inline">
                        <button
                            type="button"
                            className="btn btn-sm btn-secondary-theme"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <Calendar
                                size={18}
                                path="/media/icons/duotune/arrows/arr072.svg"
                                className="svg-icon-2 svg-icon-gray-500"
                            />
                        </button>
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                            {/*begin::Navigation*/}

                            <DateRange
                                editableDateInputs={true}
                                onChange={(item) => changeDataRange(item)}
                                moveRangeOnFirstSelection={false}
                                ranges={calenderDates}
                            />
                        </div>
                    </div>
                    &nbsp;
                    {/* sorting dropdown */}
                    <div className="dropdown dropdown-inline">
                        <button
                            type="button"
                            className="btn btn-sm btn-secondary-theme"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <Funnel
                                size={18}
                                path="/media/icons/duotune/arrows/arr072.svg"
                                className="svg-icon-2 svg-icon-gray-500"
                            />
                        </button>

                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                            {/*begin::Navigation*/}
                            <ul className="navi navi-hover" style={{ listStyle: 'none' }}>
                                <li
                                    className={`navi-item mb-2 pt-2 ${sortBy === 1 ? 'text-primary' : ''}`}
                                    onClick={(e) => setSortBy(1)}
                                >
                                    <span className="navi-text fw-bold" style={{ cursor: 'pointer' }}>
                                        Ascending
                                    </span>
                                </li>
                                <li
                                    className={`navi-item ${sortBy === -1 ? 'text-primary' : ''}`}
                                    onClick={(e) => setSortBy(-1)}
                                >
                                    <span className="navi-text fw-bold" style={{ cursor: 'pointer' }}>
                                        Descending
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/* </div> */}
                    </div>
                </div>

                {/* </div> */}
                <div className="card-body py-3">
                    <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                                <tr className="fw-bold">
                                    <th className="w-25px">
                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                            <input
                                                className="form-check-input"
                                                disabled
                                                type="checkbox"
                                                value="1"
                                                data-kt-check="true"
                                                data-kt-check-target=".widget-9-check"
                                            />
                                        </div>
                                    </th>
                                    <th className="min-w-150px">Blog Title</th>
                                    <th className="min-w-150px">Meta Title</th>
                                    <th className="min-w-150px">Keywords</th>
                                    <th className="min-w-150px">Status</th>
                                    <th className="min-w-50px">Action</th>
                                </tr>
                            </thead>
                            <tbody>{allData}</tbody>
                        </table>

                        <div className="text-center">
                            {/* <span>{totalItems}</span> */}
                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination mb-5'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* ====================================== */}
            {/* Modal */}
            {/* ====================================== */}

            <Modal onHide={handleClose} className="modal fade" size="lg" show={lgshow}>
                {/* <Modal.Header closeButton >
                <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                </Modal.Header> */}
                <div className="modal-header pb-0 border-0 justify-content-end">
                    <div className="btn btn-sm btn-icon" data-bs-dismiss="modal" onClick={handleClose}>
                        <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1 text-theme" />
                    </div>
                </div>
                <Modal.Body className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                    <div className="text-center mb-13">
                        <h1 className="mb-3">{modalTitle}</h1>
                    </div>

                    <Form onSubmit={handleBlogForm}>
                        <Form.Group className="mt-3" controlId="BlogTitle">
                            <Form.Label>
                                Blog Title <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                className="form-control-sm"
                                type="text"
                                placeholder="Enter blog title"
                                value={blogTitle}
                                onChange={(e) => setBlogTitle(e.target.value)}
                                required
                                disabled={viewMode}
                            />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="MetaTitle">
                            <Form.Label>
                                Meta Title <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                className="form-control-sm"
                                type="text"
                                placeholder="Enter meta title"
                                value={blogMetaTitle}
                                onChange={(e) => setBlogMetaTitle(e.target.value)}
                                required
                                disabled={viewMode}
                            />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="MetaKeywords">
                            <Form.Label>
                                Meta Keywords <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                className="form-control-sm"
                                type="text"
                                placeholder="Enter meta keywords"
                                value={blogMetaKeywords}
                                onChange={(e) => setBlogMetaKeywords(e.target.value)}
                                required
                                disabled={viewMode}
                            />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="MetaDescription">
                            <Form.Label>
                                Meta Description <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                className="form-control-sm"
                                type="text"
                                placeholder="Enter meta description"
                                value={blogMetaDescription}
                                onChange={(e) => setBlogMetaDescription(e.target.value)}
                                required
                                disabled={viewMode}
                            />
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="formBasicStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                className="form-select-sm"
                                value={blogStatus}
                                onChange={(e) => setBlogStatus(e.target.value)}
                                disabled={viewMode}
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="formBasicPhoto" className="mt-5 mb-2">
                            <Form.Label>
                                Thumbnail 1<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Text className="text-muted">
                                {fileSizeErrorThumbnail1 && (
                                    <div className="text-danger">(File size must be less than 300kb)</div>
                                )}
                                {fileFormatErrorThumbnail1 && (
                                    <div className="text-danger">(File must be jpg / png / svg)</div>
                                )}
                            </Form.Text>
                            {viewMode == true ? (
                                ''
                            ) : (
                                <Form.Control
                                    className="form-control-sm"
                                    type="file"
                                    placeholder="Enter Category Name"
                                    onChange={handleThumbnail1}
                                />
                            )}
                        </Form.Group>
                        {isEditThumbnail1 ? (
                            <img src={thumbnail_selected1} alt="" width="100px" height="100px" className="mb-3" />
                        ) : (
                            ''
                        )}
                        <Form.Group controlId="formBasicPhoto" className="mt-5 mb-2">
                            <Form.Label>
                                Thumbnail 2<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Text className="text-muted">
                                {fileSizeErrorThumbnail2 && (
                                    <div className="text-danger">(File size must be less than 300kb)</div>
                                )}
                                {fileFormatErrorThumbnail2 && (
                                    <div className="text-danger">(File must be jpg / png / svg)</div>
                                )}
                            </Form.Text>
                            {viewMode == true ? (
                                ''
                            ) : (
                                <Form.Control
                                    className="form-control-sm"
                                    type="file"
                                    placeholder="Enter Category Name"
                                    onChange={handleThumbnail2}
                                />
                            )}
                        </Form.Group>
                        {isEditThumbnail2 ? (
                            <img src={thumbnail_selected2} alt="" width="100px" height="100px" className="mb-3" />
                        ) : (
                            ''
                        )}
                        <Form.Group className="mt-5 mb-2">
                            <Form.Label>Short Description</Form.Label>
                            <textarea
                                className="form-control"
                                rows="3"
                                placeholder="Enter short description"
                                value={blogDescription}
                                onChange={(e) => setBlogDescription(e.target.value)}
                                disabled={viewMode}
                            ></textarea>
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="BlogContent1">
                            <Form.Label>
                                Blog Content 1 <span className="text-danger">*</span>
                            </Form.Label>
                            <div>
                                <ReactQuill
                                    style={{ height: '200px' }}
                                    theme="snow"
                                    value={quillValue1}
                                    onChange={setQuillValue1}
                                    modules={modules}
                                    disabled={viewMode}
                                />
                            </div>
                        </Form.Group>
                        <br />
                        <br />
                        <br />
                        <br />
                        <Form.Group className="mt-4" controlId="BlogContent2">
                            <Form.Label>
                                Blog Content 2 <span className="text-danger">*</span>
                            </Form.Label>
                            <div>
                                <ReactQuill
                                    style={{ height: '200px' }}
                                    theme="snow"
                                    value={quillValue2}
                                    onChange={setQuillValue2}
                                    modules={modules}
                                    disabled={viewMode}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                {/* modal footer */}
                {viewMode ? (
                    ''
                ) : (
                    <div className="modal-footer d-flex justify-content-center pt-4">
                        <button
                            type="button"
                            className="btn btn-color-gray-400 btn-silent-theme px-6"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-flex btn-dark"
                            id="btn-submit"
                            onClick={handleBlogForm}
                        >
                            {modalTitle}
                        </button>
                    </div>
                )}
            </Modal>
        </>
    )
}

export default Blogs
