import React from 'react'
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import axios from 'axios'
const SetPassword = () => {
    /* eslint-disable jsx-a11y/anchor-is-valid */

    const [contentLoading, setContentLoading] = useState(true)
    useEffect(() => {
        // validate token and user
        const user = window.location.pathname.split('/')[3]
        const token = window.location.pathname.split('/')[4]
        axios
            .get(`${process.env.REACT_APP_API_URL}/adminuser/validate-token/${user}/${token}`)
            .then((res) => {
                if (res.data.status === true) {
                    setValidToken(true)
                } else {
                    setValidToken(false)
                }
            })
            .catch((err) => {
                console.log(err)
                setValidToken(false)
            })
            .finally(() => {
                setContentLoading(false)
            })
    }, [])

    const setPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Password is required'),
        cpassword: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Confirm password is required'),
    })

    const initialValues = {
        password: '',
        cpassword: '',
    }

    const [loading, setLoading] = useState(false)
    const [validToken, setValidToken] = useState(false)
    // const {saveAuth, setCurrentUser} = useAuth()
    const [showPasswordChecked1, setShowPasswordChecked1] = useState(false)
    const [showPasswordChecked2, setShowPasswordChecked2] = useState(false)
    const [hasErrors, setHasErrors] = useState(false)

    const navigate = useNavigate()
    const formik = useFormik({
        initialValues,
        validationSchema: setPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setStatus()
            setHasErrors(false)
            try {
                if (values.password !== values.cpassword) {
                    setHasErrors(true)
                    setSubmitting(false)
                    setLoading(false)
                    setStatus('Password and confirm password does not match')

                    return
                }
                const user = window.location.pathname.split('/')[3]
                const token = window.location.pathname.split('/')[4]
                const res = await axios.patch(
                    `${process.env.REACT_APP_API_URL}/adminuser/set-password/${user}/${token}`,
                    values,
                )
                if (res.data.status === true) {
                    setSubmitting(false)
                    setLoading(false)
                    setStatus('Password set successfully')
                    // redirect 5 seconds later
                    setTimeout(() => {
                        navigate('/auth/login')
                    }, 3000)
                } else {
                    console.log('cds')
                    setSubmitting(false)
                    setLoading(false)
                    setStatus(res.data.message)
                }
            } catch (error) {
                setSubmitting(false)
                setLoading(false)
                console.log(error)
                setStatus('Something went wrong. Please try again later.')
            }
        },
    })

    const passwordShowChecked1 = (e) => {
        setShowPasswordChecked1(e.target.checked)
    }

    const passwordShowChecked2 = (e) => {
        setShowPasswordChecked2(e.target.checked)
    }

    return (
        <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
            {/* begin::Heading */}
            <div className="text-center mb-10">
                {/* logo from public folder */}
                <img src={toAbsoluteUrl('/media/logos/logo-icon.webp')} className="mb-3" alt="Logo" width="80" />
            </div>
            {!contentLoading ? (
                <>
                    {validToken ? (
                        <>
                            <div className="text-center mb-11">
                                <h1 className="text-dark fw-bolder mb-3">Set Password</h1>
                                {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
                            </div>

                            {!!formik.status && hasErrors && (
                                <div className="mb-lg-15 alert alert-danger">
                                    <div className="alert-text font-weight-bold">
                                        {formik.status ||
                                            'Sorry, looks like there are some errors detected, please try again.'}
                                    </div>
                                </div>
                            )}

                            {!!formik.status && !hasErrors && (
                                <div className="mb-10 alert alert-success rounded">
                                    <div>{formik.status || 'Password set successfully'}</div>
                                </div>
                            )}

                            {/* begin::Form group */}
                            <div className="fv-row mb-2">
                                <label className="form-label fs-6 fw-bolder text-dark">Password</label>
                                <input
                                    placeholder="Password"
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.password && formik.errors.password,
                                        },
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        },
                                    )}
                                    // type='password'
                                    type={showPasswordChecked1 ? 'text' : 'password'}
                                    name="password"
                                    autoComplete="off"
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="form-check form-check-custom form-check-solid  mb-8">
                                <input
                                    className="form-check-input"
                                    style={{ border: '1px solid #ccc' }}
                                    type="checkbox"
                                    checked={showPasswordChecked1}
                                    onChange={(e) => passwordShowChecked1(e)}
                                    name="showPassword"
                                    id="showPassword"
                                />
                                <label className="form-check-label" htmlFor="showPass">
                                    Show Password
                                </label>
                            </div>
                            {/* end::Form group */}

                            {/* begin::Form group */}
                            <div className="fv-row mb-3">
                                <label className="form-label fw-bolder text-dark fs-6 mb-0">Confirm Passowrd</label>
                                <input
                                    // type='password'
                                    type={showPasswordChecked2 ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    autoComplete="off"
                                    {...formik.getFieldProps('cpassword')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.cpassword && formik.errors.cpassword,
                                        },
                                        {
                                            'is-valid': formik.touched.cpassword && !formik.errors.cpassword,
                                        },
                                    )}
                                />
                                {formik.touched.cpassword && formik.errors.cpassword && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{formik.errors.cpassword}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="form-check form-check-custom form-check-solid">
                                <input
                                    className="form-check-input"
                                    style={{ border: '1px solid #ccc' }}
                                    type="checkbox"
                                    checked={showPasswordChecked2}
                                    onChange={(e) => passwordShowChecked2(e)}
                                    name="showPassword"
                                    id="showPassword"
                                />
                                <label className="form-check-label" htmlFor="showPass">
                                    Show Password
                                </label>
                            </div>
                            {/* end::Form group */}

                            {/* begin::Wrapper */}
                            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                <div />

                                {/* begin::Link */}

                                {/* end::Link */}
                            </div>
                            {/* end::Wrapper */}

                            {/* begin::Action */}
                            <div className="d-grid mb-10">
                                <button
                                    type="submit"
                                    id="kt_sign_in_submit"
                                    className="btn btn-primary"
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && <span className="indicator-label">Continue</span>}
                                    {loading && (
                                        <span className="indicator-progress" style={{ display: 'block' }}>
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                            {/* end::Action */}
                        </>
                    ) : (
                        <>
                            <div className="text-center mb-11">
                                <h1 className="text-dark fw-bolder mb-3">Invalid Request</h1>
                                <p>
                                    Your request token has been used/ expired, if you didn't create password kindly
                                    reset your password or contact support
                                </p>
                                {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="text-center mb-11">
                        <p className="text-dark fw-bolder mb-3">Loading...</p>
                        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
                    </div>
                </>
            )}

            <div className="text-gray-500 text-center fw-semibold fs-6">
                <Link to="/auth/login" className="link-primary">
                    Sign in
                </Link>
            </div>
        </form>
    )
}

export default SetPassword
